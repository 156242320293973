export const PROJECT_STATES = 'Project States';
export const PROJECT_STATUS = 'Project Status';
export const PROJECT_OUTLOOK = 'Project Outlook';
export const PROJECT_STAGES = 'Project Stages';

export const tagColors = {
  [PROJECT_STATES]: ['#989898', '#4674ca', '#b0d2a1', '#989898'],
  [PROJECT_STATUS]: ['#b0d2a1', '#FFCCCC']
};

export const initialCompanyConfiguration = {
  projectStages: { selected: [], notSelected: [] },
  sponsoringDepartment: { selected: [], notSelected: [] },
  milestones: { selected: [], notSelected: [] },
  resourceDiscipline: { selected: [], notSelected: [] },
  projectCategory: { selected: [], notSelected: [] },
  projectPriorityLevel: { selected: [], notSelected: [] }
};

export const initialGlobalConfiguration = {
  projectStages: [],
  sponsoringDepartment: [],
  milestones: [],
  projectStatus: [],
  projectStates: [],
  projectCategory: [],
  projectPriorityLevel: []
};

export const methodologyType = {
  AGILE: 'agile',
  SDLC: 'sdlc',
  AGILE_CAPS: 'AGILE',
  SDLC_CAPS: 'SDLC',
  AGILE_SDLC: 'AGILE_SDLC'
};

export const metricNameStatusColor = {
  Active: '#12B76A',
  Hold: '#FF0000',
  Completed: '#3e79f7'
};

export const projectMetricsConfig = [
  { title: 'component.projectMetrics.projectStates', key: 'projectStates' },
  { title: 'component.projectMetrics.projectStatus', key: 'projectStatus' },
  { title: 'component.projectMetrics.projectOutlook', key: 'projectOutlook' },
  {
    title: 'component.projectMetrics.projectStages', key: 'projectStages', buttonTitle: 'component.projectMetrics.addNewStage', buttonKey: 'STAGES'
  },
  {
    title: 'component.projectMetrics.milestones', key: 'milestones', buttonTitle: 'component.projectMetrics.addNewMilestone', buttonKey: 'MILESTONES'
  },
  {
    title: 'component.projectMetrics.projectPriority', key: 'projectPriorityLevel', buttonTitle: 'component.projectMetrics.addNewLevel', buttonKey: 'PRIORITY'
  },
  {
    title: 'component.projectMetrics.projectCategory', key: 'projectCategory', buttonTitle: 'component.projectMetrics.addNewCategory', buttonKey: 'CATEGORY'
  },
  {
    title: 'component.projectMetrics.sponsoringDepartment', key: 'sponsoringDepartment', buttonTitle: 'component.projectMetrics.addNewDepartment', buttonKey: 'DEPARTMENT'
  },
  {
    title: 'component.projectMetrics.resourceDiscipline', key: 'resourceDiscipline', buttonTitle: 'component.projectMetrics.addNewResourceDiscipline', buttonKey: 'RESOURCE'
  }
];
